import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Link } from "gatsby";
import Layout from "../../components/layout";
import Seo from "../../components/seo"

const Jobs = ({location}) => {
  return (
    <Layout>
      <Seo
        pathName={location.pathname}
        title="Jobs"
      />
      <section className="pt-6">
        <div className="container">
          <div class="row">
            <div className="col mb-4">
              <h1>Jobs</h1>
            </div>
          </div>
          <div className="row row-cols-1 row-cols-md-2">
            <div className="col mb-4">
              <div className="card custom-cards h-100 p-0">
                <Link to="/jobs/monitoring-solutions-engineer/">
                  <StaticImage
                    src="../../images/jobs/729_MonitoringSolutionsEngineer-1080x675.png"
                    alt="Dream Job"
                    className="mb-4"
                  />
                  <div className="card-body">
                    <h2>Monitoring Solutions Engineer</h2>
                    <p className="text-dark">
                      The Monitoring Solutions Engineer role will be responsible
                      for working with clients who need assistance with managing
                      and setting up New Relic within their company
                      infrastructure.
                    </p>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col mb-4">
              <div className="card custom-cards h-100 p-0">
                <Link to="/jobs/sales-and-design-consultant/">
                  <StaticImage
                    src="../../images/jobs/729_Sales-and-Design-Consultant-01-1080x675.png"
                    alt="Dream Job"
                    className="mb-4"
                  />
                  <div className="card-body">
                    <h2>Sales and Design Consultant</h2>
                    <p className="text-dark">
                      The perfect person for this role will have both UI/UX and
                      Sales experience, and be able to read between the lines
                      when a client needs those efforts (Because surprise!
                      clients don’t always SAY that’s what they need).
                    </p>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col mb-4">
              <div className="card custom-cards h-100 p-0">
                <Link to="/jobs/zendesk-architect/">
                  <StaticImage
                    src="../../images/jobs/729_ZendeskArchitecht-01-1080x675.png"
                    alt="Dream Job"
                    className="mb-4"
                  />
                  <div className="card-body">
                    <h2>Zendesk Architect</h2>
                    <p className="text-dark">
                      You will be counted on as an expert in the Zendesk Suite
                      of tools. Candidates should demonstrate strong Project
                      Management and Customer Service skills.
                    </p>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col mb-4">
                <div className="card custom-cards h-100 p-0">
                <Link to="/jobs/heres-lookin-at-you/">
                    <StaticImage
                    src="../../images/jobs/729_DreamJob-01-1080x675.png"
                    alt="Dream Job"
                    className="mb-4"
                    />
                    <div className="card-body">
                    <h2>Here's Lookin' At You</h2>
                    <p className="text-dark">
                        Don’t see a role that fits your skillset? No worries! Let’s
                        talk anyway. We are always open to a conversation, a coffee
                        date, and to talk about what would make you a good addition
                        to our team.
                    </p>
                    </div>
                </Link>
                </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Jobs;
